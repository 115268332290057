

































































































import { Vue, Component, Mixins } from 'vue-property-decorator'

import TileSlider from '@/components/TileSlider.vue'
import TilePanel from '@/components/TilePanel.vue'
import TilePanelPlaceholder from '@/components/TilePanelPlaceholder.vue'
import TileSliderPlaceholder from '@/components/TileSliderPlaceholder.vue'
import UiLoader from '@/components/UiLoader.vue'
import { fetchTrendingBeats, fetchBeatWithDemoDownload, TrendingPeriod } from '@/api/beats'
import { fetchBeats, BeatFilterType } from '~/api/beats'
import { fetchAllArtistTypes } from '../api/artist-type'
import { prettifyApiError } from '../../shared/services/prettify-api-error'
import { signup, login } from '../../shared/api/auth'
import { analyticsGetFreeBeats } from '../../shared/services/analytics'
import { AuthStore, IOnLoginSuccess } from '../../shared/store/auth'
import { addFreeBeatsToAccount } from '~/api/user'
import { RandomArtistType } from '@/mixins/random-artist-type'

@Component({ components: { TileSlider, TilePanel, TilePanelPlaceholder, TileSliderPlaceholder, UiLoader } })
export default class WelcomePage extends Mixins(RandomArtistType) {
	isLoading = false
	trendingBeats: IBeat[] = []
	beatForDemoDownload: IBeat = null
	artistTypes: IArtistType[] = []
	randomArtistTypeBeats: IBeat[] = []
	user: ISignupParams = {
		first_name: '',
		email: '',
		password: ''
	}
	signupError = ''

	@AuthStore.Getter('isAuthenticated') isAuthenticated: boolean

	@AuthStore.Action('onLoginSuccess') onLoginSuccess: (params: IOnLoginSuccess) => void

	async addFreeBeatsToAccountAndRedirect() {
		try {
			this.isLoading = true
			await addFreeBeatsToAccount()
			analyticsGetFreeBeats()
			Vue.router.push({ name: 'Purchases' })
		} finally {
			this.isLoading = false
		}
	}

	async signup() {
		try {
			this.isLoading = true
			const signupParams = { ...this.user, password1: this.user.password, password2: this.user.password }
			await signup(signupParams)
			// do login
			const { key } = await login({ email: this.user.email, password: this.user.password })
			this.onLoginSuccess({ key, doRedirect: false })
			this.addFreeBeatsToAccountAndRedirect()
		} catch (err) {
			this.signupError = prettifyApiError(err)
			this.isLoading = false
		}
	}

	validateFields() {
		this.signup()
	}

	async fetchTrendingBeats() {
		this.trendingBeats = await fetchTrendingBeats(10, TrendingPeriod.AllTime, null)
	}

	async fetchBeatWithDemoDownload() {
		this.beatForDemoDownload = await fetchBeatWithDemoDownload()
	}

	async fetchRandomArtistTypeBeats() {
		this.randomArtistTypeBeats = await fetchBeats({
			filterType: BeatFilterType.ArtistType,
			slug: this.randomArtistType.slug,
			limit: 5
		})
	}

	async fetchAllArtistTypes() {
		this.artistTypes = await fetchAllArtistTypes(true)
	}

	created() {
		this.fetchTrendingBeats()
		this.fetchBeatWithDemoDownload()
		this.fetchRandomArtistTypeBeats()
		this.fetchAllArtistTypes()
	}
}
