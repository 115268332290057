var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view" }, [
    _c("header", { staticClass: "header header--heroHalf" }, [
      _c("div", { staticClass: "container grid grid__col2" }, [
        _c("div", { staticClass: "container--md" }, [
          _vm._m(0),
          _vm._m(1),
          _vm.isAuthenticated
            ? _c(
                "a",
                {
                  staticClass: "btn btn--lg btn--primary",
                  on: {
                    click: function ($event) {
                      return _vm.addFreeBeatsToAccountAndRedirect()
                    },
                  },
                },
                [
                  _vm.isLoading
                    ? _c("UiLoader", {
                        staticClass: "btn__icon btn__icon--loader",
                      })
                    : _c("span", [_vm._v("CLAIM YOUR FREE BEATS")]),
                ],
                1
              )
            : _vm._e(),
        ]),
        !_vm.isAuthenticated
          ? _c(
              "div",
              { staticClass: "container container--xxs" },
              [
                _c("ValidationObserver", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var handleSubmit = ref.handleSubmit
                          return [
                            _c(
                              "div",
                              { staticClass: "form__title h-textLeft" },
                              [
                                _c("h3", [
                                  _vm._v("Get started absolutely free."),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "form form--fullWidth formLayout",
                                attrs: { autocomplete: "off" },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "name",
                                    rules: { required: true },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "formGroup",
                                                class: {
                                                  hasError: errors.length > 0,
                                                },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.user.first_name,
                                                      expression:
                                                        "user.first_name",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "formInput formInput--fullWidth",
                                                  class: {
                                                    hasError: errors.length > 0,
                                                  },
                                                  attrs: {
                                                    name: "name",
                                                    type: "text",
                                                    placeholder: "Full Name",
                                                  },
                                                  domProps: {
                                                    value: _vm.user.first_name,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.user,
                                                        "first_name",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "formInput__hint formInput__hint--error",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "email",
                                    rules: { required: true, email: true },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "formGroup",
                                                class: {
                                                  hasError: errors.length > 0,
                                                },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.user.email,
                                                      expression: "user.email",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "formInput formInput--fullWidth",
                                                  class: {
                                                    hasError: errors.length > 0,
                                                  },
                                                  attrs: {
                                                    name: "email",
                                                    type: "email",
                                                    placeholder:
                                                      "Email Address",
                                                  },
                                                  domProps: {
                                                    value: _vm.user.email,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.user,
                                                        "email",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "formInput__hint formInput__hint--error",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "password",
                                    rules: { required: true, min: 8 },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "formGroup",
                                                class: {
                                                  hasError: errors.length > 0,
                                                },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.user.password,
                                                      expression:
                                                        "user.password",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "formInput formInput--fullWidth",
                                                  class: {
                                                    hasError: errors.length > 0,
                                                  },
                                                  attrs: {
                                                    name: "password",
                                                    type: "password",
                                                    placeholder: "Password",
                                                    autocomplete:
                                                      "new-password",
                                                  },
                                                  domProps: {
                                                    value: _vm.user.password,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.user,
                                                        "password",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: errors[0],
                                                        expression: "errors[0]",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "formInput__hint formInput__hint--error",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Password must be at least 8 characters"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm.signupError
                                  ? _c("p", { staticClass: "form__error" }, [
                                      _vm._v(_vm._s(_vm.signupError)),
                                    ])
                                  : _vm._e(),
                                _c("div", { staticClass: "form__submit" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn--fullWidth btn--primary",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return handleSubmit(
                                            _vm.validateFields
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm.isLoading
                                        ? _c("UiLoader", {
                                            staticClass:
                                              "btn__icon btn__icon--loader",
                                          })
                                        : _c("span", [
                                            _vm._v(
                                              "SIGNUP AND GET 3 FREE BEATS"
                                            ),
                                          ]),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2755801955
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _c("section", { staticClass: "section" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "wrapper" }, [
          _c(
            "h3",
            { staticClass: "browse__section-title" },
            [
              _vm._v("Trending"),
              _c(
                "router-link",
                { staticClass: "see-all", attrs: { to: { name: "Trending" } } },
                [
                  _vm._v("See All"),
                  _c("icon", {
                    staticClass: "icon-arrow-right",
                    attrs: { name: "arrow-right" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "gridBrowser grid--scroll-lte-tablet" },
            [
              _vm.trendingBeats.length === 0
                ? _vm._l(10, function (n) {
                    return _c("tile-panel-placeholder", { key: n })
                  })
                : _vm._l(_vm.trendingBeats, function (beat, index) {
                    return _c("tile-panel", {
                      key: beat.id,
                      attrs: {
                        beat: beat,
                        index: index,
                        loadedBeats: _vm.trendingBeats,
                      },
                    })
                  }),
            ],
            2
          ),
        ]),
      ]),
    ]),
    _vm.beatForDemoDownload
      ? _c(
          "section",
          { staticClass: "section section--darkBackground h-mt-70" },
          [
            _c(
              "div",
              { staticClass: "container container--sm grid--demoDownload" },
              [
                _c(
                  "div",
                  { staticClass: "iconBox" },
                  [_c("icon", { attrs: { name: "download" } })],
                  1
                ),
                _vm._m(2),
                _c("tile-panel", {
                  attrs: {
                    beat: _vm.beatForDemoDownload,
                    index: 0,
                    loadedBeats: [].concat(_vm.beatForDemoDownload),
                  },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _c("section", { staticClass: "section h-mt-70" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "wrapper" }, [
          _c(
            "h3",
            { staticClass: "browse__section-title" },
            [
              _vm._v(_vm._s(_vm.randomArtistType.name) + " Type Beats"),
              _c(
                "router-link",
                {
                  staticClass: "see-all",
                  attrs: {
                    to: {
                      name: "ArtistType",
                      params: { slug: _vm.randomArtistType.slug },
                    },
                  },
                },
                [
                  _vm._v("See All"),
                  _c("icon", {
                    staticClass: "icon-arrow-right",
                    attrs: { name: "arrow-right" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "gridBrowser grid--scroll-lte-tablet" },
            [
              _vm.randomArtistTypeBeats.length === 0
                ? _vm._l(5, function (n) {
                    return _c("tile-panel-placeholder", { key: n })
                  })
                : _vm._l(_vm.randomArtistTypeBeats, function (beat, index) {
                    return _c("tile-panel", {
                      key: beat.id,
                      attrs: {
                        beat: beat,
                        index: index,
                        loadedBeats: _vm.randomArtistTypeBeats,
                      },
                    })
                  }),
            ],
            2
          ),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "section h-mt-70" }, [
      _c("div", { staticClass: "container grid grid__col2" }, [
        _c("img", {
          staticClass: "macbookMockup",
          attrs: { src: "img/macbook-mockup.png" },
        }),
        _c(
          "div",
          [
            _c("h1", { staticClass: "h1" }, [
              _vm._v("It’s easy to find your sound."),
            ]),
            _vm._m(3),
            _c(
              "router-link",
              {
                staticClass: "btn btn--primary",
                attrs: {
                  to: { name: "ArtistType", params: { slug: "drake" } },
                },
              },
              [_vm._v("Drake Type Beats")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm.artistTypes.length > 0
      ? _c("section", { staticClass: "section h-mt-70" }, [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "wrapper" },
              [
                _c(
                  "h3",
                  { staticClass: "browse__section-title" },
                  [
                    _vm._v("Artist Types"),
                    _c(
                      "router-link",
                      {
                        staticClass: "see-all",
                        attrs: { to: { name: "ArtistTypes" } },
                      },
                      [
                        _vm._v("See All"),
                        _c("icon", {
                          staticClass: "icon-arrow-right",
                          attrs: { name: "arrow-right" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("tile-slider", {
                  attrs: {
                    "parent-state": "artistTypes",
                    "loaded-items": _vm.artistTypes,
                  },
                }),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "h1" }, [
      _vm._v("Join the "),
      _c("span", { staticClass: "h1--primary" }, [_vm._v("TheCharts")]),
      _vm._v(" community today, let’s create together."),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "p--leading" }, [
      _vm._v("Create your free account and get "),
      _c("span", { staticClass: "h-textUnderline" }, [_vm._v("3 free beats")]),
      _vm._v(", instantly."),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h1", { staticClass: "h1" }, [_vm._v("Free Demo Downloads")]),
      _c("p", { staticClass: "p--leading" }, [
        _vm._v("Download the demo version of our beats "),
        _c("span", { staticClass: "textColorPrimary" }, [_vm._v("for free")]),
        _vm._v(
          " and start recording today, and come back later when you are ready to purchase."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "p--leading" }, [
      _vm._v("In TheCharts, you can browse beats by "),
      _c("span", { staticClass: "textColorPrimary" }, [_vm._v("Artist Type")]),
      _vm._v(" and find the sound you are looking for right away."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }