import { Vue, Component } from 'vue-property-decorator'

@Component
export class RandomArtistType extends Vue {
    get randomArtistType() {
        const artistTypesSlugs = [
            { name: 'Drake', slug: 'drake' },
            { name: 'Meek Mill', slug: 'meek-mill' },
            { name: 'Future', slug: 'future' },
            { name: 'Post Malone', slug: 'post-malone' },
            { name: 'Travis Scott', slug: 'travis-scott' },
            { name: 'Kendrick Lamar', slug: 'kendrick-lamar' },
            { name: 'Kevin Gates', slug: 'kevin-gates' },
            { name: 'Lil Uzi Vert', slug: 'lil-uzi-vert' },
            { name: 'French Montana', slug: 'french-montana' }
        ]
        return artistTypesSlugs[Math.floor(Math.random() * artistTypesSlugs.length)]
    }
}
